import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import './Main.css'

const PrivacyPolicy = lazy(()=> import('../../../Pages/PrivacyPolicy'))
const Home = lazy(() => import('../../../Pages/Home'))
const About = lazy(() => import('../../../Pages/About'))
const Support = lazy(() => import('../../../Pages/Support'))
const Projects = lazy(() => import('../../../Pages/Projects'))

const Main = (props) => {
    return (
        <div className="App">
            <Router>
                <Suspense fallback={<div>Page is loading ...</div>}>
                    <Switch>
                        <Route
                            path="/projects" render={() => (
                                <Projects {...props} />
                            )}
                        />
                        <Route
                            path="/about" render={() => (
                                <About {...props} />
                            )}
                        />
                        <Route
                            path="/home" render={() => (
                                <Home {...props} />
                            )}
                        />
                        <Route
                            path="/privacy" render={() => (
                                <PrivacyPolicy {...props} />
                            )}
                        />
                        <Route
                            path="/support" render={() => (
                                <Support {...props} />
                            )}
                        />
                        <Route render={() => (
                            <Redirect to="/home" />
                        )}
                        />
                    </Switch>
                </Suspense>
            </Router>
        </div>
    );
}

export default Main
