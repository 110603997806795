import './Header.css'

const Header = (props) => {
    return (
        <div className="header">
            <div className="menu">
                <div className="menu-item"><a href="/home" className="menu-link"><img src={props.logo} className="home-logo" alt="logo-dark" /></a></div>
                <div className="menu-item"><a href="/projects" className="menu-link">Projects</a></div>
                <div className="menu-item"><a href="/about" className="menu-link">About</a></div>
            </div>
        </div>
    );
}

export default Header;
