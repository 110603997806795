import logo from './Resources/logo_white.svg';
import logoDark from './Resources/logo_dark.svg';
import { Header, Footer, Main } from './Components/Layouts'
import './Styles/App.css';

const App = () => {
  return (
    <div className="app-container">
      <Header logo={logoDark}/>
      <Main logo={logo} />
      <Footer logo={logoDark} />
    </div>
  );
}

export default App;
