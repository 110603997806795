import './Footer.css'

const Footer = (props) => {
    return (
        <div className="footer">
            <div className="footer-top">
                <div className="footer-left">
                    <div className="footer-link"><a href="/privacy" className="footer-link">Privacy Policy</a></div>
                    <div className="footer-link"><a href="/support" className="footer-link">Support</a></div>
                </div>
                <div className="footer-right">
                    <div className="footer-contact">support@wbates.com</div>
                    <div className="footer-contact"><a href="/home" className="footer-link"><img src={props.logo} className="mini-logo" alt="logo-dark" /></a></div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="copyright-box">Copyright © 2021 WBates.com | Powered by Netlify</div>
            </div>
        </div>
    );
}

export default Footer;
